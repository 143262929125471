import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 55;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>更新頻度を減らします</li>
        <li>なぜ毎週更新するようになったか</li>
        <li>1年弱続けて見えてきたこと</li>
        <li>自分たちのための活動</li>
        <li>コミュニケーション能力の訓練</li>
        <li>準備の重要性</li>
        <li>アウトプットの場</li>
        <li>続けることの大変なところ</li>
        <li>目の前で起こることや体験を大切にしたい</li>
        <li>今年の箕澤屋について</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
